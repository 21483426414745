@import "mixins/breakpoints";
@import "variables";

.breakpoint-container {
  &.breakpoint--mobile {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  &.breakpoint--tablet {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &.breakpoint--desktop {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &.breakpoint--large-desktop {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}
